/* Custom font not found */
@font-face {
	font-family: "icons";
	src: url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff2?afa155ab01425b5578e92934061734f4") format("woff2"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff?afa155ab01425b5578e92934061734f4") format("woff"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.ttf?afa155ab01425b5578e92934061734f4") format("truetype"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.svg?afa155ab01425b5578e92934061734f4#icons") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* line 18, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon {
	font-family: "icons";
	display: inline-block;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* line 38, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-404:before {
	content: "\f101";
}

/* line 41, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-410:before {
	content: "\f102";
}

/* line 44, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-acres:before {
	content: "\f103";
}

/* line 47, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-add:before {
	content: "\f104";
}

/* line 50, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent-wfp:before {
	content: "\f105";
}

/* line 53, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent:before {
	content: "\f106";
}

/* line 56, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-alert:before {
	content: "\f107";
}

/* line 59, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-appleid:before {
	content: "\f108";
}

/* line 62, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-area:before {
	content: "\f109";
}

/* line 65, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-full:before {
	content: "\f10a";
}

/* line 68, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-slim:before {
	content: "\f10b";
}

/* line 71, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-triangle:before {
	content: "\f10c";
}

/* line 74, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down:before {
	content: "\f10d";
}

/* line 77, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-custom:before {
	content: "\f10e";
}

/* line 80, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-full:before {
	content: "\f10f";
}

/* line 83, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-slim:before {
	content: "\f110";
}

/* line 86, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-small:before {
	content: "\f111";
}

/* line 89, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-thin:before {
	content: "\f112";
}

/* line 92, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left:before {
	content: "\f113";
}

/* line 95, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-custom:before {
	content: "\f114";
}

/* line 98, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-full:before {
	content: "\f115";
}

/* line 101, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-slim:before {
	content: "\f116";
}

/* line 104, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-small:before {
	content: "\f117";
}

/* line 107, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-thin:before {
	content: "\f118";
}

/* line 110, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right:before {
	content: "\f119";
}

/* line 113, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-double-full:before {
	content: "\f11a";
}

/* line 116, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-full:before {
	content: "\f11b";
}

/* line 119, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up:before {
	content: "\f11c";
}

/* line 122, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrows:before {
	content: "\f11d";
}

/* line 125, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention-triangle:before {
	content: "\f11e";
}

/* line 128, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention:before {
	content: "\f11f";
}

/* line 131, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-back:before {
	content: "\f120";
}

/* line 134, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath-custom:before {
	content: "\f121";
}

/* line 137, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath:before {
	content: "\f122";
}

/* line 140, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed-custom:before {
	content: "\f123";
}

/* line 143, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed:before {
	content: "\f124";
}

/* line 146, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bike:before {
	content: "\f125";
}

/* line 149, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-blog:before {
	content: "\f126";
}

/* line 152, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-boy:before {
	content: "\f127";
}

/* line 155, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-building:before {
	content: "\f128";
}

/* line 158, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-buildings:before {
	content: "\f129";
}

/* line 161, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calculator:before {
	content: "\f12a";
}

/* line 164, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar-2:before {
	content: "\f12b";
}

/* line 167, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar:before {
	content: "\f12c";
}

/* line 170, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-car:before {
	content: "\f12d";
}

/* line 173, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-carret-down:before {
	content: "\f12e";
}

/* line 176, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction-reversed:before {
	content: "\f12f";
}

/* line 179, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction:before {
	content: "\f130";
}

/* line 182, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked-fill:before {
	content: "\f131";
}

/* line 185, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked:before {
	content: "\f132";
}

/* line 188, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-clock-slim:before {
	content: "\f133";
}

/* line 191, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close-slim:before {
	content: "\f134";
}

/* line 194, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close:before {
	content: "\f135";
}

/* line 197, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-cloud-search:before {
	content: "\f136";
}

/* line 200, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-collapsed:before {
	content: "\f137";
}

/* line 203, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comment:before {
	content: "\f138";
}

/* line 206, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comments:before {
	content: "\f139";
}

/* line 209, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-compare:before {
	content: "\f13a";
}

/* line 212, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-delete:before {
	content: "\f13b";
}

/* line 215, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-directions:before {
	content: "\f13c";
}

/* line 218, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-down:before {
	content: "\f13d";
}

/* line 221, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-draw:before {
	content: "\f13e";
}

/* line 224, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-earth:before {
	content: "\f13f";
}

/* line 227, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-edit:before {
	content: "\f140";
}

/* line 230, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-energy-certificates:before {
	content: "\f141";
}

/* line 233, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand-down:before {
	content: "\f142";
}

/* line 236, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand:before {
	content: "\f143";
}

/* line 239, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-extend:before {
	content: "\f144";
}

/* line 242, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link-alt:before {
	content: "\f145";
}

/* line 245, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link:before {
	content: "\f146";
}

/* line 248, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye-off:before {
	content: "\f147";
}

/* line 251, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye:before {
	content: "\f148";
}

/* line 254, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook-2:before {
	content: "\f149";
}

/* line 257, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook:before {
	content: "\f14a";
}

/* line 260, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-favicon-wfp:before {
	content: "\f14b";
}

/* line 263, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more-reverse:before {
	content: "\f14c";
}

/* line 266, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more:before {
	content: "\f14d";
}

/* line 269, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter:before {
	content: "\f14e";
}

/* line 272, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag-market:before {
	content: "\f14f";
}

/* line 275, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag:before {
	content: "\f150";
}

/* line 278, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flickr:before {
	content: "\f151";
}

/* line 281, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flipbook:before {
	content: "\f152";
}

/* line 284, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-floor-plan:before {
	content: "\f153";
}

/* line 287, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-full-screen:before {
	content: "\f154";
}

/* line 290, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-funnel:before {
	content: "\f155";
}

/* line 293, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery-view:before {
	content: "\f156";
}

/* line 296, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery:before {
	content: "\f157";
}

/* line 299, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gas:before {
	content: "\f158";
}

/* line 302, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gear:before {
	content: "\f159";
}

/* line 305, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-girl:before {
	content: "\f15a";
}

/* line 308, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-globe:before {
	content: "\f15b";
}

/* line 311, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart-full:before {
	content: "\f15c";
}

/* line 314, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart:before {
	content: "\f15d";
}

/* line 317, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f15e";
}

/* line 320, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f15f";
}

/* line 323, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-1-full:before {
	content: "\f160";
}

/* line 326, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-2-full:before {
	content: "\f161";
}

/* line 329, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-frame:before {
	content: "\f162";
}

/* line 332, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f163";
}

/* line 335, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f164";
}

/* line 338, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-wfp:before {
	content: "\f165";
}

/* line 341, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house:before {
	content: "\f166";
}

/* line 344, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-info:before {
	content: "\f167";
}

/* line 347, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-instagram:before {
	content: "\f168";
}

/* line 350, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-link:before {
	content: "\f169";
}

/* line 353, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-linkedin:before {
	content: "\f16a";
}

/* line 356, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view-full:before {
	content: "\f16b";
}

/* line 359, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view:before {
	content: "\f16c";
}

/* line 362, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location-target:before {
	content: "\f16d";
}

/* line 365, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location:before {
	content: "\f16e";
}

/* line 368, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in-slim:before {
	content: "\f16f";
}

/* line 371, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in:before {
	content: "\f170";
}

/* line 374, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-line:before {
	content: "\f171";
}

/* line 377, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-new:before {
	content: "\f172";
}

/* line 380, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-slim:before {
	content: "\f173";
}

/* line 383, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail:before {
	content: "\f174";
}

/* line 386, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-slim:before {
	content: "\f175";
}

/* line 389, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view-full:before {
	content: "\f176";
}

/* line 392, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view:before {
	content: "\f177";
}

/* line 395, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-market-data:before {
	content: "\f178";
}

/* line 398, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-matterPort3dVR:before {
	content: "\f179";
}

/* line 401, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-median:before {
	content: "\f17a";
}

/* line 404, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu-spaced:before {
	content: "\f17b";
}

/* line 407, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu:before {
	content: "\f17c";
}

/* line 410, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-minus:before {
	content: "\f17d";
}

/* line 413, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-multiple-listings:before {
	content: "\f17e";
}

/* line 416, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-openhouse:before {
	content: "\f17f";
}

/* line 419, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pan:before {
	content: "\f180";
}

/* line 422, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause-thin:before {
	content: "\f181";
}

/* line 425, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause:before {
	content: "\f182";
}

/* line 428, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pdf:before {
	content: "\f183";
}

/* line 431, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-2:before {
	content: "\f184";
}

/* line 434, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-sign-up:before {
	content: "\f185";
}

/* line 437, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person:before {
	content: "\f186";
}

/* line 440, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-2:before {
	content: "\f187";
}

/* line 443, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic-full:before {
	content: "\f188";
}

/* line 446, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic:before {
	content: "\f189";
}

/* line 449, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-slim:before {
	content: "\f18a";
}

/* line 452, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone:before {
	content: "\f18b";
}

/* line 455, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-photo:before {
	content: "\f18c";
}

/* line 458, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pin:before {
	content: "\f18d";
}

/* line 461, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest-2:before {
	content: "\f18e";
}

/* line 464, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest:before {
	content: "\f18f";
}

/* line 467, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-empty:before {
	content: "\f190";
}

/* line 470, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-round:before {
	content: "\f191";
}

/* line 473, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-square:before {
	content: "\f192";
}

/* line 476, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-plus:before {
	content: "\f193";
}

/* line 479, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-portfolio:before {
	content: "\f194";
}

/* line 482, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-printer:before {
	content: "\f195";
}

/* line 485, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-quote:before {
	content: "\f196";
}

/* line 488, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-radius:before {
	content: "\f197";
}

/* line 491, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-refine:before {
	content: "\f198";
}

/* line 494, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-remove:before {
	content: "\f199";
}

/* line 497, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-restaurant:before {
	content: "\f19a";
}

/* line 500, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-return:before {
	content: "\f19b";
}

/* line 503, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-ribbon:before {
	content: "\f19c";
}

/* line 506, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rotate:before {
	content: "\f19d";
}

/* line 509, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rss:before {
	content: "\f19e";
}

/* line 512, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-school:before {
	content: "\f19f";
}

/* line 515, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-schools:before {
	content: "\f1a0";
}

/* line 518, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-search:before {
	content: "\f1a1";
}

/* line 521, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-share:before {
	content: "\f1a2";
}

/* line 524, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up-slim:before {
	content: "\f1a3";
}

/* line 527, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up:before {
	content: "\f1a4";
}

/* line 530, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-signin:before {
	content: "\f1a5";
}

/* line 533, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-split-view:before {
	content: "\f1a6";
}

/* line 536, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-empty:before {
	content: "\f1a7";
}

/* line 539, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-half:before {
	content: "\f1a8";
}

/* line 542, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star:before {
	content: "\f1a9";
}

/* line 545, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-stop:before {
	content: "\f1aa";
}

/* line 548, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-street-view:before {
	content: "\f1ab";
}

/* line 551, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-switch:before {
	content: "\f1ac";
}

/* line 554, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tag:before {
	content: "\f1ad";
}

/* line 557, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-toggle-fullscreen:before {
	content: "\f1ae";
}

/* line 560, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-transit:before {
	content: "\f1af";
}

/* line 563, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-trash:before {
	content: "\f1b0";
}

/* line 566, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tumblr:before {
	content: "\f1b1";
}

/* line 569, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-twitter:before {
	content: "\f1b2";
}

/* line 572, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-units:before {
	content: "\f1b3";
}

/* line 575, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-up:before {
	content: "\f1b4";
}

/* line 578, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-upload:before {
	content: "\f1b5";
}

/* line 581, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vcard:before {
	content: "\f1b6";
}

/* line 584, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-video:before {
	content: "\f1b7";
}

/* line 587, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vimeo:before {
	content: "\f1b8";
}

/* line 590, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-virtual-tour:before {
	content: "\f1b9";
}

/* line 593, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume-mute:before {
	content: "\f1ba";
}

/* line 596, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume:before {
	content: "\f1bb";
}

/* line 599, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-walk:before {
	content: "\f1bc";
}

/* line 602, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wechat:before {
	content: "\f1bd";
}

/* line 605, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wfp:before {
	content: "\f1be";
}

/* line 608, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-whatsapp:before {
	content: "\f1bf";
}

/* line 611, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wordpress:before {
	content: "\f1c0";
}

/* line 614, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-world:before {
	content: "\f1c1";
}

/* line 617, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-yelp:before {
	content: "\f1c2";
}

/* line 620, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube-2:before {
	content: "\f1c3";
}

/* line 623, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube:before {
	content: "\f1c4";
}

/* line 626, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin-slim:before {
	content: "\f1c5";
}

/* line 629, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin:before {
	content: "\f1c6";
}

/* line 632, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout-slim:before {
	content: "\f1c7";
}

/* line 635, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout:before {
	content: "\f1c8";
}

/* line 638, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomreset:before {
	content: "\f1c9";
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-left], .is-rtl
.icon-custom[class*=arrow-left] {
	transform: rotate(180deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-right], .is-rtl
.icon-custom[class*=arrow-right] {
	transform: rotate(-180deg);
}

/* line 16, scss/60-components/_nearby-location.scss */
.c-target-locations-icon {
	top: 50%;
	transform: translate(-0, -50%);
	z-index: 1;
	position: absolute;
	cursor: pointer;
	right: 10px;
	height: auto;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-one .c-target-locations-icon {
	right: 0px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-one .c-target-locations-icon {
	right: auto;
	left: 0px;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-two .c-target-locations-icon {
	right: 0px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-two .c-target-locations-icon {
	right: auto;
	left: 0px;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-three .c-target-locations-icon {
	right: 30px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-three .c-target-locations-icon {
	right: auto;
	left: 30px;
}

/* line 14, scss/70-modules/landingpagesearch/_module.scss */
.m-lpsw {
	display: block;
	position: relative;
}

/* line 19, scss/70-modules/landingpagesearch/_module.scss */
.m-lpsw .lpsw__radius-value {
	width: 100%;
}

/* line 23, scss/70-modules/landingpagesearch/_module.scss */
.m-lpsw .tabs__item--alt.tabs__item--selected {
	background-color: rgb(var(--color-global-positive));
	color: rgb(var(--color-global-secondary));
}

/* line 28, scss/70-modules/landingpagesearch/_module.scss */
.m-lpsw.combo-widget {
	padding: 10px;
	border: 1px solid rgb(var(--color-global-border));
	background-color: rgb(var(--color-global-primary));
}

/* line 34, scss/70-modules/landingpagesearch/_module.scss */
.m-lpsw .target-locations-parent {
	padding-right: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-lpsw .target-locations-parent {
	padding-right: initial;
	padding-left: 30px;
}

@media only screen and (min-width: 60em) {
	/* line 41, scss/70-modules/landingpagesearch/_module.scss */
	.m-lpsw .lpsw__wrapper--hpic .c-search-suggest.suggest-columns-1 .suggest-container__suggest-panel {
		max-width: 33.3333%;
	}
}

/* line 49, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__districts-modal-toggle {
	right: 10px;
	position: absolute;
	top: 5px;
	font-size: 10px;
	color: rgb(var(--color-global-secondary));
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__districts-modal-toggle {
	right: auto;
	left: 10px;
}

/* line 58, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__selected-districts {
	padding-right: 20px;
	height: auto;
	line-height: 20px;
	border: 1px solid rgb(var(--color-global-border));
	background: transparent;
	padding-top: 5px;
	box-sizing: border-box;
	cursor: pointer;
	user-select: none;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__selected-districts {
	padding-right: initial;
	padding-left: 20px;
}

/* line 69, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__selected-districts:not(.is-empty) .lpsw__selected-districts-label {
	display: none;
}

/* line 74, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__selected-districts-label {
	font-style: italic;
	font-weight: 400;
	color: rgb(var(--color-global-helper));
	padding: 0 5px;
	margin: 0 5px 5px 5px;
}

/* line 82, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-item {
	margin-left: 10px;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__school-district-item {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .lpsw__school-district-item {
		margin-left: 0;
	}
}

/* line 88, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-item:empty {
	display: none;
}

/* line 93, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-label,
.lpsw__selected-districts-label {
	display: inline-block;
	height: 18px;
	line-height: 18px;
}

/* line 100, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-label,
.lpsw__school-district-label:hover {
	padding: 0 25px 0 10px;
	border-radius: 20px;
	background: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	white-space: nowrap;
	position: relative;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__school-district-label, .is-rtl
.lpsw__school-district-label:hover {
	padding: 0 10px 0 25px;
}

/* line 110, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-label .icon {
	top: 50%;
	transform: translate(-0, -50%);
	right: 10px;
	position: absolute;
	top: 50%;
	font-size: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__school-district-label .icon {
	right: auto;
	left: 10px;
}

/* line 118, scss/70-modules/landingpagesearch/_module.scss */
.is-open + .lpsw__school-district-embed {
	display: block;
}

/* line 122, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-embed {
	position: relative;
	display: none;
}

/* line 126, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__school-district-embed .school_districts_lpscontrol {
	z-index: 49;
	position: absolute;
	left: 0;
	right: 0;
	background: transparent;
	padding: 20px;
}

/* line 137, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__literal-links {
	direction: rtl;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__literal-links {
	direction: ltr;
	unicode-bidi: bidi-override;
}

@media only screen and (max-width: 29.999em) {
	/* line 140, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__literal-links--full {
		direction: ltr;
		width: 100%;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .lpsw__literal-links--full {
		direction: rtl;
		unicode-bidi: bidi-override;
	}
}

/* line 151, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__search-wrapper {
	margin-left: -4%;
	display: table;
	width: 108%;
	border-collapse: separate;
	border-spacing: 15px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__search-wrapper {
	margin-left: initial;
	margin-right: -4%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .lpsw__search-wrapper {
		margin-left: 0;
	}
}

/* line 159, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__price {
	margin-bottom: 5px;
}

/* line 166, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__price--label,
.change-currency--label {
	font-size: 0.92em;
	font-weight: 600;
	display: inline-block;
	text-transform: uppercase;
}

@media only screen and (max-width: 47.999em) {
	/* line 166, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__price--label,
	.change-currency--label {
		font-size: 1.0718em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 166, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__price--label,
	.change-currency--label {
		font-size: 0.92em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 166, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__price--label,
	.change-currency--label {
		font-size: 0.92em;
	}
}

/* line 174, scss/70-modules/landingpagesearch/_module.scss */
.change-currency {
	font-size: inherit;
}

/* line 178, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__submit {
	display: block;
	text-align: center;
	padding: 5px 10px;
}

/* line 183, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__submit:not(.o-button) {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 47.999em) {
	/* line 183, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__submit:not(.o-button) {
		background-color: rgb(var(--color-global-secondary));
		color: rgb(var(--color-dynamic-primary-color));
	}
}

/* line 196, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__submit--bottom .lpsw__submit-text {
	display: block !important;
}

/* line 201, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__submit .lpsw__submit-text {
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 178, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__submit {
		padding: 5px;
	}
}

@media only screen and (min-width: 48em) {
	/* line 210, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__submit .hide-lap {
		display: none;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 216, scss/70-modules/landingpagesearch/_module.scss */
	.lpsw__submit .lpsw__submit-text {
		display: none;
	}
}

/* line 221, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__submit:hover {
	color: rgb(var(--color-global-positive));
	transition: all .25s linear;
}

/* line 228, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__suggest-wrapper,
.lpsw__suggest-term-wrapper {
	position: relative;
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 233, scss/70-modules/landingpagesearch/_module.scss */
	.property-type-label,
	.lifestyles-label {
		display: none;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 233, scss/70-modules/landingpagesearch/_module.scss */
	.property-type-label,
	.lifestyles-label {
		display: none;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 233, scss/70-modules/landingpagesearch/_module.scss */
	.property-type-label,
	.lifestyles-label {
		display: none;
	}
}

/* line 249, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__map-wrapper {
	display: table;
	width: 100%;
	min-height: 165px;
	border: 1px solid #ccc;
	box-sizing: border-box;
}

/* line 257, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__schools-wrapper {
	height: 165px;
	overflow: auto;
	padding: 10px;
	box-sizing: border-box;
}

/* line 264, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__price-range--widget {
	right: 0;
	display: block;
	position: absolute;
	bottom: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__price-range--widget {
	right: auto;
	left: 0;
}

/* line 272, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__slider-wrapper {
	position: relative;
	margin-bottom: 2px;
}

/* line 278, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__tabs-label {
	padding: 10px 20px;
}

/* line 282, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__tabs-link {
	display: block;
	padding: 10px 20px;
	color: rgb(var(--color-global-secondary));
	background-color: rgb(var(--color-global-title));
}

/* line 288, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__tabs-link:hover {
	color: rgb(var(--color-global-positive));
	background-color: rgb(var(--color-global-secondary));
}

/* line 295, scss/70-modules/landingpagesearch/_module.scss */
.is-more-menu .lpsw__tabs,
.is-single-menu .lpsw__tabs {
	display: block;
}

/* line 300, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__tabs.is-single-menu {
	width: 80%;
}

/* line 304, scss/70-modules/landingpagesearch/_module.scss */
.searchby__item {
	position: relative;
	white-space: nowrap;
	padding: 0 5px;
}

/* line 309, scss/70-modules/landingpagesearch/_module.scss */
.searchby__item .input-box__item {
	padding-top: 0;
}

/* line 314, scss/70-modules/landingpagesearch/_module.scss */
.searchby__list {
	display: flex;
	align-items: center;
}

/* line 320, scss/70-modules/landingpagesearch/_module.scss */
.searchby__item--title .o-title {
	vertical-align: initial;
}

/* line 325, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__suggest-term {
	height: 30px;
}

/* line 328, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__container--hpic .lpsw__suggest-term {
	height: unset;
}

/* line 333, scss/70-modules/landingpagesearch/_module.scss */
.is-more-list .searchby__item {
	margin: 5px 0;
	display: block;
}

/* line 339, scss/70-modules/landingpagesearch/_module.scss */
.is-searchbyAll .searchby__item--title {
	display: none;
}

/* line 344, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__filters-container .list-bare__item {
	padding-top: 5px;
	display: block;
}

/* line 350, scss/70-modules/landingpagesearch/_module.scss */
.map {
	height: 190px;
}

/* line 354, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__container--hpic.with-border-radius .lpsw__section--hpic--search-input,
.lpsw__container--hpic.with-border-radius .lpsw__section-content--new .lpsw__suggest-term {
	border-radius: 5px;
}

/* line 361, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__container--hpic.with-input-background-color .lpsw__section--hpic--search-input,
.lpsw__container--hpic.with-input-background-color .lpsw__section-content--new .lpsw__suggest-term {
	background-color: #f7f7f7;
}

/* line 368, scss/70-modules/landingpagesearch/_module.scss */
.lpsw__suggest-term.o-input {
	background-color: #f7f7f7;
}

@keyframes lpsw__toggle-keyframes-show {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes lpsw__toggle-keyframes-hide {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(20px);
	}
}

/* line 50, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.m-lpsw .content-box__wrapper {
	padding: 0;
}

/* line 55, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.m-lpsw .search-suggest__detail--address {
	max-width: 35%;
}

/* line 61, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__container--hpic {
	left: 0;
	top: 0;
	background-color: rgba(var(--color-global-negative), .5);
	position: absolute;
}

/* line 68, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic {
	width: 50%;
	margin: 0 auto;
	padding: 15px;
}

@media only screen and (max-width: 59.999em) {
	/* line 68, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic {
		width: auto;
	}
}

/* line 77, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper {
	position: relative;
}

/* line 81, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.cityhomes-lpsw-homepage .lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-search-suggest {
	background-color: #5b5b5b;
}

/* line 86, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-search-suggest {
	background-color: rgba(var(--color-global-negative), 0.6);
	border: 0;
	max-height: 0;
	transition: max-height .7s ease;
}

/* line 92, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-search-suggest.is-ready {
	max-height: 65vh;
}

/* line 97, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-target-locations-icon {
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 103, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .suggest-container::-webkit-scrollbar {
	width: 1em;
}

/* line 107, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .suggest-container::-webkit-scrollbar-track {
	background-color: rgb(var(--color-global-primary));
}

/* line 111, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .suggest-container::-webkit-scrollbar-thumb {
	background-color: rgb(var(--color-global-border));
}

/* line 116, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-search-suggest .category-container .lpssuggest-group-data {
	margin: 10px;
	background-color: rgb(var(--color-global-positive));
}

/* line 121, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-wrapper .suggest-container__suggest-panel {
	padding: 10px 0;
	margin: 0 auto;
}

@media only screen and (min-width: 48em) {
	/* line 121, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__suggest-wrapper .suggest-container__suggest-panel {
		max-width: 85vw;
	}
}

/* line 131, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term,
.lpsw__wrapper--hpic .lpsw__keyword-term {
	font-family: var(--font-family-text);
	font-size: 1.083em;
	line-height: 1.6em;
	padding: 10px;
	text-overflow: ellipsis;
	color: #000;
	border: 0;
	font-display: swap;
}

/* line 144, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term::-webkit-input-placeholder,
.lpsw__wrapper--hpic .lpsw__keyword-term::-webkit-input-placeholder {
	padding: 0;
	color: #919191;
	font-style: normal;
}

/* line 152, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term::-ms-input-placeholder,
.lpsw__wrapper--hpic .lpsw__keyword-term::-ms-input-placeholder {
	padding: 0;
	color: #919191;
	font-style: normal;
}

/* line 159, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term::-moz-placeholder,
.lpsw__wrapper--hpic .lpsw__keyword-term::-moz-placeholder {
	padding: 0;
	color: #919191;
	font-style: normal;
}

/* line 166, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term:-moz-placeholder,
.lpsw__wrapper--hpic .lpsw__keyword-term:-moz-placeholder {
	padding: 0;
	color: #919191;
	font-style: normal;
}

/* line 173, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__suggest-term::placeholder,
.lpsw__wrapper--hpic .lpsw__keyword-term::placeholder {
	padding: 0;
	color: #919191;
	font-style: normal;
}

/* line 181, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--select-type {
	border-radius: 2px 0 0 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(var(--color-global-primary));
	background-color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section--hpic--select-type {
	border-radius: 0 2px 2px 0;
}

/* line 189, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--select-type .icon-arrow-right-full {
	padding-right: 5px;
	font-size: 0.92em;
	color: rgb(var(--color-global-primary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section--hpic--select-type .icon-arrow-right-full {
	padding-right: initial;
	padding-left: 5px;
}

/* line 196, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-control {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 10px;
}

/* line 204, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic .lpsw__toggle {
	z-index: 2;
}

/* line 208, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic .lpsw__toggle-wrapper {
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1.1px;
	cursor: pointer;
}

/* line 214, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic .lpsw__toggle-wrapper .option {
	display: block;
	font-size: 1.167em;
	opacity: 0;
	user-select: none;
	line-height: 1;
	white-space: nowrap;
	color: rgb(var(--color-global-primary));
}

/* line 224, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic .lpsw__toggle-wrapper .show {
	animation: lpsw__toggle-keyframes-show .5s;
	animation-fill-mode: forwards;
}

/* line 229, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic .lpsw__toggle-wrapper .hide {
	animation: lpsw__toggle-keyframes-hide .5s;
	animation-fill-mode: forwards;
	height: 0;
}

/* line 237, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-input {
	flex-basis: 100%;
	padding: 0;
	border-left: 1px solid rgb(var(--color-global-border));
	display: flex;
	align-items: center;
}

/* line 245, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-button {
	background: transparent;
}

/* line 248, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-button .o-button {
	border-radius: 0 2px 2px 0;
	border: 0;
	font-size: 1em;
	height: 100%;
	background-color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section--hpic--search-button .o-button {
	border-radius: 2px 0 0 2px;
}

/* line 255, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-button .o-button:not(.c-prev-next-ctrls-btn) {
	padding: 15px;
}

/* line 259, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-button .o-button .lpsw__submit-text {
	display: none;
}

/* line 263, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section--hpic--search-button .o-button .icon-search {
	display: block;
	font-size: 1.167em;
}

/* line 271, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content {
	justify-content: flex-end;
	flex-basis: 100%;
}

@media only screen and (min-width: 60em) {
	/* line 278, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .c-search-suggest.suggest-columns-1.active-suggest-columns-1 .suggest-container__suggest-panel {
		max-width: unset;
	}
	/* line 283, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .c-search-suggest.category.suggest-columns-2 {
		width: 100%;
	}
}

@media only screen and (min-width: 60em) and (max-width: 79.999em) {
	/* line 289, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__suggest-wrapper .c-search-suggest.is-ready {
		left: 50%;
		transform: translateX(-50%);
	}
	/* line 294, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .c-search-suggest.category.suggest-columns-2 {
		width: 100vw;
		max-width: 75vw;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 301, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .c-search-suggest.category.suggest-columns-2 {
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: unset;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 312, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__data--block {
		overflow: hidden;
	}
}

/* line 317, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest {
	margin-top: 15px;
}

@media only screen and (min-width: 80em) {
	/* line 317, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest {
		left: 50%;
		transform: translate(-50%, -0);
	}
	/* line 321, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest.active-suggest-columns-4 {
		min-width: 80vw;
	}
	/* line 325, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest.active-suggest-columns-3 {
		min-width: 80vw;
	}
	/* line 329, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest.active-suggest-columns-2 {
		width: 100vw;
		max-width: 65vw;
	}
	/* line 334, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest.active-suggest-columns-1 {
		min-width: 100%;
		width: 100%;
	}
}

/* line 342, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__tabs,
.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__pills {
	width: auto;
}

/* line 347, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__pills {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__pills {
	padding-left: initial;
	padding-right: 20px;
}

/* line 351, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__search-type-tabs {
	padding-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__search-type-tabs {
	padding-left: initial;
	padding-right: 0;
}

@media only screen and (max-width: 59.999em) {
	/* line 357, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__pills {
		padding-left: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .c-search-suggest .search-suggest__pills {
		padding-left: initial;
		padding-right: 0;
	}
	/* line 361, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls {
		display: flex;
		font-size: 12px;
		margin-bottom: 10px;
		border-bottom: 1px solid white;
		max-height: 28px;
	}
	/* line 368, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs {
		flex: 0 0 auto;
		overflow: initial;
	}
	/* line 372, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs--wrapper {
		flex-direction: column;
		display: flex;
		overflow: hidden;
		max-height: 28px;
	}
	/* line 378, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs--wrapper .tabs__item:not(:first-child) {
		border-radius: 0;
	}
	/* line 382, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs--wrapper:hover {
		max-height: none;
	}
	/* line 387, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs--wrapper .tabs__item {
		padding: 5px 10px;
		text-transform: capitalize;
		margin: 0;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border: 1px solid #ccc;
		background: #ccc;
	}
	/* line 396, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs--wrapper .tabs__item--selected {
		background: rgb(var(--color-global-positive));
	}
	/* line 401, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider {
		flex-direction: column;
		display: flex;
		overflow: hidden;
		max-height: 28px;
	}
	/* line 407, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider .tabs__item:not(.tabs__item--selected) {
		border-radius: 0;
	}
	/* line 411, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider:hover {
		max-height: none;
	}
	/* line 415, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider .tabs__item.tabs__item--selected {
		order: 1;
	}
	/* line 418, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider .tabs__item.tabs__item--selected::after {
		content: "";
		position: absolute;
		right: 5px;
		top: 10px;
		width: 0;
		height: 0;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-top: 5px solid rgb(var(--color-global-secondary));
		clear: both;
	}
	/* line 432, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider .tabs__item {
		order: 2;
		text-align: left;
		padding: 5px 20px 5px 10px;
		text-transform: capitalize;
		margin: 0;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border: 1px solid #ccc;
		background: #ccc;
	}
	/* line 443, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__tabs .search-suggest__search-type-tabs.scroll-carousel__slider .tabs__item--selected {
		background: rgb(var(--color-global-positive));
	}
	/* line 450, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls .search-suggest__pills {
		flex: 1 1 auto;
	}
}

/* line 456, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls {
	margin: 0 20px 0 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__controls {
	margin: 0 20px 0 20px;
}

/* line 460, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .suggest-container__suggest-panel {
	max-width: 100%;
	padding-right: 10px;
}

/* line 465, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term {
	padding-left: 40px;
	font-size: 1em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term {
	padding-left: initial;
	padding-right: 40px;
}

@media only screen and (max-width: 47.999em) {
	/* line 465, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 465, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 465, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term {
		font-size: 1em;
	}
}

/* line 477, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .lpsw__suggest-term-wrapper .lpsw__icon {
	z-index: 1;
	margin: 13px 15px 10px 13px;
	position: absolute;
	cursor: pointer;
	font-size: 1.2em;
}

/* line 488, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__control {
	border-radius: 4px 4px 0 0;
	padding: 7px 10px 7px 10px;
	margin-right: 0;
	text-transform: inherit;
	background: #bdbdbd;
	text-align: center;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__control {
	border-radius: 4px 4px 0 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__control {
	padding: 7px 10px 7px 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__control {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .lpsw__wrapper--hpic .lpsw__section-content--new .search-suggest__control {
		margin-right: 0;
	}
}

/* line 497, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .lpsw__section-content--new .tabs__item--selected {
	background: rgb(var(--color-global-positive));
}

/* line 502, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__wrapper--hpic .target-locations-parent {
	padding-right: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .lpsw__wrapper--hpic .target-locations-parent {
	padding-right: initial;
	padding-left: 30px;
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 510, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
	.m-lpsw.l-responsive .c-search-suggest.category.suggest-columns-2 {
		left: -7.5%;
		max-width: 92vw;
	}
}

/* line 517, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.paw-content .lpsw__container--hpic {
	background-color: rgba(var(--color-global-negative), .5);
}

/* line 521, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__container--hpic:not(.with-transparent-background) {
	background-color: rgba(var(--color-global-negative), .5);
}

/* line 525, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__container--hpic:not(.with-transparent-background) .lpsw__section-content--new .c-search-suggest {
	background-color: #5b5b5b;
}

/* line 530, scss/80-themes/AffinityDefault/70-modules/landingpagesearch/module.scss */
.lpsw__icon {
	color: #54565a;
}

/*# sourceMappingURL=../../../../../true */